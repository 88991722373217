import React from "react"
import Layout from "../../components/layout"
import CommonProduct from "../../components/common/CommonProduct"
import image from "../../../static/Client/Hostel.png"
import {
  IoBedOutline,
  IoCardOutline,
  IoConstructOutline,
  IoFastFoodOutline,
} from "react-icons/io5"
const Hostel = () => {
  const data = {
    name: "Hostel Management System",
    description:
      "Our Hostel Management System is a comprehensive solution designed to simplify and streamline hostel operations. From room allocations and fee management to attendance tracking and maintenance requests, our platform ensures smooth and efficient hostel management for administrators, staff, and residents alike.",
    image: image,
    features: [
      {
        id: 1,
        icon: <IoBedOutline />,
        title: "Efficient Room Allocation",
        description:
          "Simplify the room allocation process with an intuitive system that allows administrators to assign rooms based on availability, preferences, and eligibility. The platform ensures fair and transparent room assignments while reducing administrative workload.",
      },
      {
        id: 2,
        icon: <IoCardOutline />,
        title: "Seamless Fee Management",
        description:
          "Manage hostel fees with ease through automated billing, payment tracking, and timely reminders. The system supports various payment methods and generates detailed financial reports, making fee collection hassle-free for both residents and administrators.",
      },

      {
        id: 4,
        icon: <IoConstructOutline />,
        title: "Maintenance Request Handling",
        description:
          "Enable residents to raise maintenance requests easily and track their status in real time. From plumbing issues to electrical repairs, the system helps hostel staff respond promptly and manage requests efficiently.",
      },
    ],
  }

  return (
    <Layout>
      <CommonProduct
        name={data.name}
        description={data.description}
        image={data.image}
        features={data.features}
      />
    </Layout>
  )
}

export default Hostel
